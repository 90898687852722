import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/_service/category.service';

import { AddcategoryComponent } from 'src/app/category/addcategory/addcategory.component';
import { UpdatecategoryComponent } from '../updatecategory/updatecategory.component';

import { Category } from 'src/app/_model/category';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.css'],
})
export class CategorylistComponent implements OnInit {
  categoriesResponse: any;
  categoriesResponseData: any;
  category: Category = new Category();
  updateCategoryStatusResponse: any;
  reverse: boolean = true;
  expression: string = 'timeStamp';

  constructor(
    private categoryService: CategoryService,
    private bsModalService: BsModalService,
    private toastrService: ToastrService) {
    this.getCategories();
  }

  ngOnInit(): void {
    this.refreshCategoryList();
  }

  /** Get All categories */
  getCategories() {
    this.categoryService.getCategories()
      .subscribe((response) => {
        this.categoriesResponse = response;
        if (this.categoriesResponse.err == 0) {
          this.categoriesResponseData = this.categoriesResponse.categories;
        }
      });
  }

  /**Category Block And Unblock */
  categoryBlockUnblock(event, categoryId) {
    if (event) {
      this.category.status = 'active';
    } else if (!event) {
      this.category.status = 'unblock';
    }

    this.categoryService
      .blockUblockCategory(categoryId, this.category)
      .subscribe((response) => {
        this.updateCategoryStatusResponse = response;
        if (this.updateCategoryStatusResponse.err == 0) {
          this.getCategories();
          this.toastrService.success(`${this.updateCategoryStatusResponse.msg}`);
        } else {
          this.toastrService.error(`${this.updateCategoryStatusResponse.msg}`);
        }
      });
  }

  /**Open Add Category Modal */
  addCategoryModal() {
    this.bsModalService.show(AddcategoryComponent, Object.assign({}, { class: 'modal-dialog-centered', ignoreBackdropClick: true }));
  }

  /** Reload Category List When Add New Category */
  refreshCategoryList() {
    this.categoryService.categoryState
      .subscribe((response) => {
        if (response) {
          this.getCategories();
        }
      });
  }

  /**edit category */
  editCategory(selectedCategory) {
    this.bsModalService.show(UpdatecategoryComponent,
      Object.assign(
        { initialState: { selectedCategory: selectedCategory } },
        { class: 'modal-dialog-centered', ignoreBackdropClick: true }
      )
    );
  }
}
