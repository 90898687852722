<body style="
    background-image: url(https://res.cloudinary.com/tradnory/image/upload/v1604322753/asset/AdminHomeBG.jpg);
  " class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading"
  id="headerToggle">
  <!--Header Mobile-->
  <div id="kt_header_mobile" class="header-mobile">
    <!--Logo-->
    <a routerLink="/">
      <img alt="Logo" height="30"
        src="https://res.cloudinary.com/tradnory/image/upload/v1599552505/asset/admin%20logo.png"
        class="logo-default max-h-30px" />
      <i class="logo-default max-h-30px"></i>
    </a>
    <!--Toolbar-->
    <div class="d-flex align-items-center">
      <button class="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
        <span>
          <i class="la la-bars"></i>
        </span>
      </button>
    </div>
  </div>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <!--Header-->
        <app-header></app-header>
        <!--Content-->
        <div class="content d-flex flex-column flex-column-fluid">
          <!--Subheader-->
          <div class="subheader py-2 py-lg-12 subheader-transparent">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <!--Info-->
              <div class="d-flex align-items-center flex-wrap mr-1">
                <!--Heading-->
                <div class="d-flex flex-column">
                  <!--Title-->
                  <h2 class="text-white font-weight-bold my-2 mr-5">
                    {{ heading }}
                  </h2>
                  <div class="d-flex align-items-center font-weight-bold my-2">
                    <a class="opacity-75 hover-opacity-100">
                      <i class="la la-home text-white icon-1x"></i>
                    </a>
                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a class="text-white text-hover-white opacity-75 hover-opacity-100">{{ heading }}</a>

                    <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                    <a class="text-white text-hover-white opacity-75 hover-opacity-100">{{ subHeading }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column-fluid">
            <!--Container-->
            <div class="container">
              <!--Dashboard-->
              <!--Row-->
              <div class="row">
                <div class="col-lg-12 col-xxl-12">
                  <!--Category list-->
                  <router-outlet></router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Footer-->
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</body>
