import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  APIURL = environment.APIURL;

  constructor(private httpClient: HttpClient) { }

  /** Add Product */
  addProduct(formData) {
    return this.httpClient.post(`${this.APIURL}addProduct`, formData);
  }

  /** Get Products */
  getProducts() {
    return this.httpClient.get(`${this.APIURL}getProducts`);
  }

  /** Get Products By Category */
  getProductsByCategory(categoryId) {
    return this.httpClient.get(`${this.APIURL}getProductsByCategory`, { params: { categoryId: categoryId } });
  }

  /**Get Product Details */
  getProductDetails(productId) {
    return this.httpClient.get(`${this.APIURL}getProductDetails`, { params: { productId: productId } });
  }

  /**Update product */
  updateProduct(formData) {
    return this.httpClient.put(`${this.APIURL}product/update`, formData);
  }

  /**Block And unblock Products */
  blockUnblockProduct(productId, status) {
    return this.httpClient.patch(`${this.APIURL}blockUnblockProduct`, status, { params: { productId: productId } });
  }

  /**Delete product */
  deleteProduct(productId) {
    return this.httpClient.delete(`${this.APIURL}deleteProduct`, { params: { productId: productId } });
  }

  /**Upload Video */
  uploadVideo(formData) {
    return this.httpClient.put(`${this.APIURL}updateThumbnail`, formData);
  }

  getUrl() {
    return this.httpClient.get(`${this.APIURL}metaUrl/get`);
  }
  submitUrls(formData) {
    return this.httpClient.post(`${this.APIURL}metaUrl/addUrl`, formData);
  }
  syncUrl() {
    return this.httpClient.get(`${this.APIURL}sync/syncURL`);
  }
  deleteUrl(formData) {
    return this.httpClient.post(`${this.APIURL}sync/deleteUrl`, formData);
  }
}
