import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  currentYear: number;

  constructor() {
    this.getCurrentYear();
  }

  ngOnInit(): void { }

  /**Get Current Year */
  getCurrentYear() {
    const date = new Date();
    this.currentYear = date.getFullYear();
  }
}
