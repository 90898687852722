import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../_model/product';

@Pipe({
  name: 'product',
})
export class ProductPipe implements PipeTransform {
  transform(products: Product[], query: string, categoryId: string) {
    if (query == undefined) {
      return products;
    } else if (query == undefined && categoryId == undefined) {
      return products;
    } else if (query != undefined && categoryId != undefined) {
      return products.filter(
        (product) =>
          product.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
          product.categoryId.indexOf(categoryId) !== -1
      );
    } else if (query != undefined && categoryId == undefined) {
      return products.filter(
        (product) =>
          product.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
  }
}
