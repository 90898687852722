import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/_service/category.service';

import { Category } from 'src/app/_model/category';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.css'],
})
export class AddcategoryComponent implements OnInit {
  buttonTitle: string = 'Submit';
  submit: boolean = false;
  formGroup: FormGroup;
  formData: FormData = new FormData();
  addcategoryResponse: any;
  image: any;
  category: Category = new Category();

  constructor(
    private bsModalRef: BsModalRef,
    private categoryService: CategoryService,
    private toastrService: ToastrService) {
    this.formValidation();
  }

  ngOnInit(): void { }

  /**Add Category Form Validation */
  formValidation() {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      image: new FormControl('', Validators.required),
    });
  }

  /**Get Add category Form Controls */
  get formControls() {
    return this.formGroup.controls;
  }

  /** Get Image From Form */
  detectImage(event) {
    if (event.target.files[0] && event.target.files) {
      this.image = event.target.files[0];
    }
  }

  /** Add Category */
  addCategory() {
    this.submit = true;
    if (this.formGroup.invalid) {
      return;
    } else {
      this.buttonTitle = 'Submitting...';
      this.formData.append('name', this.category.name);
      this.formData.append('description', this.category.description);
      this.formData.append('status', this.category.status);
      this.formData.append('image', this.image);
      this.categoryService.addCategory(this.formData)
        .subscribe((response) => {
          this.addcategoryResponse = response;
          if (this.addcategoryResponse.err == 0) {
            this.categoryService.updateCategoryState(true);
            this.toastrService.success(`${this.addcategoryResponse.msg}`);
            setTimeout(() => { this.closeModal(); }, 800);
          } else {
            this.buttonTitle = 'Submit';
            this.toastrService.error(`${this.addcategoryResponse.msg}`);
          }
        });
    }
  }

  /** Close Add Category Modal */
  closeModal() {
    this.bsModalRef.hide();
  }
}
