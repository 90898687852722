import { filter } from 'rxjs/Operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, Event } from '@angular/router';


import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/_service/category.service';
import { ProductService } from 'src/app/_service/product.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Product } from 'src/app/_model/product';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css'],
})
export class ProductlistComponent implements OnInit {
  product: Product = new Product();
  getProductsResponse: any;
  getProductsResponseData: any;
  expression: string = 'timeStamp';
  reverse: boolean = true;
  blockUnblockProductResponse: any;
  addProdBtnState: boolean;
  categoryResponse: any;
  categoryResponseData: any;
  categoryId: string;
  query: string;
  urlEventResponse: any;
  deleteProductResponse: any;  

  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private toastrService: ToastrService,
    private router: Router,
    private categoryService: CategoryService,
    private ngxUiLoaderService: NgxUiLoaderService) {
    this.getAddProdBtnStateOnReload();
    this.getAddProdBtnStateOnChange();
    this.getQueryParams();
  }

  ngOnInit(): void {
    this.getCategories();
   
  }

  /**Get Add Product button state on component reload  */
  getAddProdBtnStateOnReload() {
    if (this.router.url == '/products') {
      this.addProdBtnState = true;
    } else {
      this.addProdBtnState = false;
    }
  }

  /**Get add product button state when path update */
  getAddProdBtnStateOnChange() {  
    this.router.events.pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: Event) => {
        this.urlEventResponse = event;
        if (this.urlEventResponse.url == '/products') {
          this.addProdBtnState = true;
        } else {
          this.addProdBtnState = false;
        }
      });
  }

  /**Get Query Paramater */
  getQueryParams() {
    this.activatedRoute.queryParams.
      subscribe((param) => {
        this.product.categoryId = param.categoryId;
        if (this.product.categoryId != undefined) {
          this.getProductsByCategory();
        } else {
          this.getProducts();
        }
      });
  }

  /**Get All Categories */
  getCategories() {
    this.categoryService.getCategories()
      .subscribe((response) => {
        this.categoryResponse = response;
        if (this.categoryResponse.err == 0) {
          this.categoryResponseData = this.categoryResponse.categories;          
        }
      });
  }

  /** Get All Products */
  getProducts() {
    this.productService.getProducts()
      .subscribe((response) => {
        this.getProductsResponse = response;
        if (this.getProductsResponse.err == 0) {
          this.getProductsResponseData = this.getProductsResponse.products;
        }
      });
  }

  /** Get Products By CategoryID */
  getProductsByCategory() {
    this.productService.getProductsByCategory(this.product.categoryId)
      .subscribe((response) => {
        this.getProductsResponse = response;
        if (this.getProductsResponse.err == 0) {
          this.getProductsResponseData = this.getProductsResponse.products;
        }
      });
  }

  /**Block Product */
  blockUnblockProduct(productId, status) {
    this.ngxUiLoaderService.start();
    this.product.status = status;
    this.productService.blockUnblockProduct(productId, this.product)
      .subscribe((response) => {
        this.blockUnblockProductResponse = response;
        if (this.blockUnblockProductResponse.err == 0) {
          this.ngxUiLoaderService.stop();
          if (this.product.categoryId != undefined) {
            this.getProductsByCategory();
          } else {
            this.getProducts();
          }
          this.toastrService.success(`${this.blockUnblockProductResponse.msg}`);
        } else {
          this.ngxUiLoaderService.stop();
          this.toastrService.error(`${this.blockUnblockProductResponse.msg}`);
        }
      });
  }

  /**Delete product */
  deleteProduct(productId) {
    this.ngxUiLoaderService.start();
    this.productService.deleteProduct(productId)
      .subscribe((response) => {
        this.deleteProductResponse = response;
        if (this.deleteProductResponse.err == 0) {
          this.ngxUiLoaderService.stop();
          if (this.product.categoryId != undefined) {
            this.getProductsByCategory();
          } else {
            this.getProducts();
          }
          this.toastrService.success(this.deleteProductResponse.msg);
        } else {
          this.ngxUiLoaderService.stop();
          this.toastrService.error(this.deleteProductResponse.msg);
        }
      });
  }
}
