export class Product {
  public _id: string
  public categoryId: string;
  public title: string;
  public description: string;
  public material: string;
  public size: string;
  public sellPrice: string;
  public buyPrice: string;
  public image: Array<string> = [];
  public link: string;
  public keywords: string;
  public status: string;
  public useFor: string;
  public benefits: string;
  public history: string;
  public strangeStory: string;
  public query: string;
  public thumbnails: Array<string> = []
  public videos: Array<string> = []
  public metaTitle: string;
  public metaDescription: string;
  public url: string;
}
