import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  APIURL = environment.APIURL;

  constructor(private httpClient: HttpClient) { }

    /** Add Stock */
    addStock(formData) {  
      return this.httpClient.post(`${this.APIURL}addStock`, formData);
    }
}
