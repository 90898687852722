import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/Operators';
import { Product } from '../../_model/product';
import { ProductService } from 'src/app/_service/product.service';

@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.css'],
})

export class ProductdetailComponent implements OnInit {
  productId: any;
  productResponse: any;
  product: Product = new Product();
  productResponseData: any;
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService
  ) {
    this.getQueryParams();
  }

  ngOnInit(): void { }


  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.productId = params.productId;
      this.getProductDetails(this.productId);
    });
  }

  getProductDetails(productId) {
    this.productService.getProductDetails(productId).subscribe((response) => {
      this.productResponse = response;
      if (this.productResponse.err == 0) {
        this.productResponseData = this.productResponse.product;
        this.product.title = this.productResponseData.title;
        this.product.material = this.productResponseData.material;
        this.product.size = this.productResponseData.size;
        this.product.status = this.productResponseData.status;
        this.product.sellPrice = this.productResponseData.sellPrice;
        this.product.buyPrice = this.productResponseData.buyPrice;
        this.product.link = this.productResponseData.link;
        this.product.keywords = this.productResponseData.keywords;
        this.product.description = this.productResponseData.description;
        this.product.useFor = this.productResponseData.useFor;
        this.product.benefits = this.productResponseData.benefits;
        this.product.strangeStory = this.productResponseData.strangeStory;
        this.product.history = this.productResponseData.history;
        this.product.image = this.productResponseData.image;
        this.product.videos = this.productResponseData.video;
        this.product.thumbnails = this.productResponseData.thumbnail;
        this.product.metaTitle = this.productResponseData.metaTitle;
        this.product.metaDescription = this.productResponseData.metaDescription;
        this.product.url = this.productResponseData.url;
      }
    });
  }
}
