import { ProductService } from 'src/app/_service/product.service';
import { Component, OnInit, NgModule } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-indexing-url',
  templateUrl: './indexing-url.component.html',
  styleUrls: ['./indexing-url.component.css'],
})

export class IndexingUrlComponent implements OnInit {
  urls: any;
  urlsData: any;
  syncResponse: any;
  deleteData: any;
  tooltip: string = "Sync url should be grater then 20"
  status: any;
  constructor(
    private getUrlServices: ProductService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getCategories()
  }
  /**Get All Categories */
  getCategories() {
    this.getUrlServices.getUrl().subscribe((response) => {
      this.urls = response
      if (this.urls.err == 0) {
        this.urlsData = this.urls.data.metaUrls;
        if (this.urlsData > 20) {
          this.tooltip = ""
        }
      }
    });
  }

  syncUrl() {
    console.log(this.urlsData);

    if (this.urlsData.length > 20) {
      this.getUrlServices.syncUrl().subscribe((response) => {
        this.syncResponse = response;
        console.log(this.syncResponse)
        if (this.syncResponse.data.err === false) {
          this.toastrService.success(this.syncResponse.data.msg);
          this.getCategories();
        }
        else {
          this.toastrService.error(this.syncResponse.data.msg);
        }
      });
    } else {
      this.toastrService.error("Sync url should be grater then 20");
    }

  }

  deleteUrl(url) {
    let data = {
      "metaId": "641401a2b858a10f3cc647e7",
      "metaUrl": url
    }

    this.getUrlServices.deleteUrl(data).subscribe((response) => {
      this.deleteData = response;
      if (this.deleteData.err === false) {
        // this.getCategories()
        this.toastrService.success("Url Delete Successfully!");
      } else {
        this.toastrService.error("Something went wrong!");
      }
    })
  }

  checkStatus(url, index) {
    console.log("working", url);
    fetch(`https://www.tradnary.com/product/description/${url}`).then((response) => {
      this.status = response;
      if (this.status.status === 200) {
        this.toastrService.success("Url Fetch Successfully!", index + 1);
      } else {
        this.toastrService.error("Url not fetch");
      }
    }).catch(err => console.log(err))
  }

}
