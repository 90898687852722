<body class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled page-loading">
  <div class="d-flex flex-column flex-root" style="height: 100vh; background: #fff">
    <div class="error error-5 d-flex flex-row-fluid"
      style="background: #fff url(https://res.cloudinary.com/tradnory/image/upload/v1604322661/asset/Admin404.jpg)no-repeat right bottom;">
      <div class="container d-flex flex-row-fluid flex-column justify-content-md-center p-10">
        <h1 class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12 display-2">
          Oops!
        </h1>
        <p class="font-weight-boldest display-5">Something went wrong here.</p>
        <p class="font-size-h3 mb-10">
          We're working on it and we'll get it fixedas soon possible.You can
          back or use our Help Center.
        </p>
        <div class="card-toolbar">
          <a routerLink="/" class="btn btn-outline-info btn-group-lg font-weight-bolder font-size-sm">
            Go To Home
          </a>
        </div>
      </div>
    </div>
  </div>
</body>
