<body class="quick-panel-right demo-panel-right offcanvas-right header-fixed subheader-enabled">
  <div class="d-flex flex-column flex-root">
    <div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        style="background-image: url(https://res.cloudinary.com/tradnory/image/upload/v1604322719/asset/adminSigninBG.png);height: 100vh;">
        <div class="login-form text-center text-white p-7 position-relative">
          <div class="d-flex flex-center mb-15">
            <a routerLink="/">
              <img alt="Logo" height="60"
                src="https://res.cloudinary.com/tradnory/image/upload/v1599552505/asset/admin%20logo.png"
                class="logo-default max-h-40px" />
            </a>
          </div>
          <div class="login-signin">
            <div class="mb-20">
              <h3 class="opacity-40 font-weight-normal">Sign In To Admin</h3>
              <p class="opacity-40">
                Enter your details to login to your account:
              </p>
            </div>
            <!--Admin Login Form-->
            <form class="form fv-plugins-bootstrap fv-plugins-framework" [formGroup]="formGroup" (submit)="signin()">
              <!--Email Input-->
              <div class="form-group fv-plugins-icon-container">
                <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8" type="text"
                  placeholder="Email" name="email" formControlName="email" [(ngModel)]="admin.email"
                  [ngClass]="{'is-invalid': submit && formControls.email.errors}" />
                <div *ngIf="submit && formControls.email.errors"
                  class="fv-plugins-message-container invalid-feedback d-block">
                  <div *ngIf="formControls.email.errors.required">Required Email Address.</div>
                  <div *ngIf="formControls.email.errors.email">Required Valid Email Address.</div>
                </div>
              </div>
              <!--Password Input-->
              <div class="form-group fv-plugins-icon-container">
                <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                  type="password" placeholder="Password" name="password" formControlName="password"
                  [(ngModel)]="admin.password" [ngClass]="{'is-invalid': submit && formControls.password.errors}" />
                <div *ngIf="submit && formControls.password.errors"
                  class="fv-plugins-message-container invalid-feedback d-block">
                  <div *ngIf="formControls.password.errors.required">Required Password.</div>
                </div>
              </div>
              <!--Login Form Submit Button-->
              <div class="form-group text-center mt-10">
                <button type="submit" id="kt_login_signin_submit" class="btn btn-pill btn-primary opacity-90 px-15 py-3"
                  [disabled]="buttonTitle == 'Signin...'">
                  {{ buttonTitle }}
                </button>
              </div>
              <input type="hidden" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
