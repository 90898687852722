<div class="card card-custom card-stretch gutter-b">
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Trendings</span>
    </h3>
    <div class="card-toolbar">
      <a (click)="openAddTreadingModal()" class="btn btn-light-dark btn-sm font-weight-bolder font-size-sm">Add New
        Trending
      </a>
    </div>
  </div>
  <hr />
  <div class="card-body">
    <div class="example table-responsive pl-4 pr-2" style="height: 480px; overflow: auto">
      <div class="row justify-content-start" style="max-width: 1230px">
        <!--begin::Column-->
        <div *ngFor="let trending of trendingResponseData" class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
          <!--begin::Card-->
          <div class="card card-custom gutter-b card-stretch">
            <!--begin::Body-->
            <div class="card-body text-center pt-4">
              <!--begin::Toolbar-->
              <div class="card-toolbar position-absolute right-0 mr-1 pr-2 zindex-1">
                <a (click)="deleteTrending(trending?._id)" class="btn btn-circle btn-light-danger btn-sm btn-icon"><i
                    class="la la-trash"></i></a>
              </div>
              <div class="d-flex justify-content-center w-150px h-150px">
                <div class="position-absolute top-0 left-0 w-100 h-100">
                  <img src="{{ trending?.image }}" alt="" style="object-fit: cover; height: 100%; width: 100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
