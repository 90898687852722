import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  navItemUserToggle: boolean = false;

  constructor(private router: Router) {
    this.navItemToggle();
  }

  ngOnInit(): void { }

  /**Login Button and Admin Profile Toggle */
  navItemToggle() {
    if (localStorage.getItem('token') != null) {
      this.navItemUserToggle = true;
    }
  }

  /**Admin Logout */
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/signin']);
  }
}
