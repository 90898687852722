import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/_service/admin.service';

import { admin } from 'src/app/_model/admin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  buttonTitle: string = 'Signin';
  formGroup: FormGroup;
  admin: admin = new admin();
  submit: boolean = false;
  signinResponse: any;

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private adminService: AdminService) {
    this.formValidation();
  }

  ngOnInit(): void { }

  /**Sigin Form Validation */
  formValidation() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  /** Get FormControls */
  get formControls() {
    return this.formGroup.controls;
  }

  /**admin Signin */
  signin() {
    this.submit = true;
    if (this.formGroup.invalid) {
      return;
    } else {
      this.buttonTitle = 'Signin...';
      this.adminService.signin(this.admin)
        .subscribe((response) => {
          this.signinResponse = response;
          if (this.signinResponse.err == 0) {
            this.router.navigate(['/']);
            localStorage.setItem('token', this.signinResponse.token);
            this.toastrService.success(`${this.signinResponse.msg}`);
          } else {
            this.buttonTitle = 'Submit';
            this.toastrService.error(`${this.signinResponse.msg}`);
          }
        });
    }
  }
}
