import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  APIURL = environment.APIURL;
  categoryState = new BehaviorSubject<boolean>(false);
  categoryRefreshState = this.categoryState.asObservable();

  constructor(private httpClient: HttpClient) { }

  /**Add Category */
  addCategory(category) {
    return this.httpClient.post(`${this.APIURL}addCategory`, category);
  }

  /** Get  All Category*/
  getCategories() {
    return this.httpClient.get(`${this.APIURL}getCategories`);
  }

  /** Update Category And Status For Block And Unblock Category Status */
  updateCategory(categoryId, category) {
    return this.httpClient.patch(`${this.APIURL}updateCategory`, category, { params: { categoryId: categoryId } });
  }

  /**Block And Unblock Category */
  blockUblockCategory(categoryId, status) {
    return this.httpClient.patch(`${this.APIURL}blockUnblockCategory`, status, { params: { categoryId: categoryId } });
  }

  /**Update category State Value For Refresh Component When Add New Category Addded */
  updateCategoryState(value) {
    this.categoryState.next(value);
  }
}
