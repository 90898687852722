<div id="kt_header" class="header header-fixed">
  <!--Container-->
  <div class="container d-flex align-items-stretch justify-content-between">
    <!--Left-->
    <div class="d-flex align-items-stretch mr-3">
      <!--Header Logo-->
      <div class="header-logo">
        <a routerLink="/">
          <img alt="Logo" height="40"
            src="https://res.cloudinary.com/tradnory/image/upload/v1599552505/asset/admin%20logo.png"
            class="logo-default max-h-40px" />
          <img alt="Logo" height="40"
            src="https://res.cloudinary.com/tradnory/image/upload/v1599552505/asset/admin%20logo.png"
            class="logo-sticky max-h-40px" />
        </a>
      </div>
      <!--Header Menu Wrapper-->
      <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
        <!--Header Menu-->
        <div id="kt_header_menu" class="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
          <!--Header Nav-->
          <ul class="menu-nav">
            <li class="menu-item menu-item-here menu-item-submenu menu-item-rel">
              <a routerLink="/categories" class="menu-link menu-toggle" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
true}">
                <span class="menu-text">Dashboard</span>
              </a>
            </li>
            <li class="menu-item menu-item-submenu menu-item-rel">
              <a routerLink="/products" class="menu-link menu-toggle"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
true}">
                <span class="menu-text">Products</span>
              </a>
            </li>
            <li class="menu-item menu-item-submenu menu-item-rel">
              <a routerLink="/addStock" class="menu-link menu-toggle"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
true}">
                <span class="menu-text">Add stock</span>
              </a>
            </li>
            <li class="menu-item menu-item-submenu menu-item-rel">
              <a routerLink="/trending" class="menu-link menu-toggle"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
true}">
                <span class="menu-text">Trending</span>
              </a>
            </li>
            <li class="menu-item menu-item-submenu menu-item-rel">
              <a routerLink="/indexing" class="menu-link menu-toggle"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
true}">
                <span class="menu-text">Update url to google</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="topbar">
      <div class="dropdown">
        <div class="topbar-item">
          <!--Admin Profile-->
          <div id="kt_quick_user_toggle"
            class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
            <div data-toggle="dropdown" data-offset="10px 0px" class="topbar-item">
              <span class="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
                Hi,
              </span>
              <span class="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                Tradnory
              </span>
              <span class="symbol symbol-35">
                <span class="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30"><b>T</b>
                </span>
              </span>
            </div>
            <!--Admin Profile Dropdown-->
            <div class="dropdown-menu p-3 m-0 mt-3 dropdown-menu-right dropdown-menu-anim-up text-center">
              <ul class="navi navi-hover py-4">
                <li class="navi-item">
                  <a (click)="logout()" class="navi-link text-hover-info"><i aria-hidden="true"
                      class="la la-sign-out text-dark mr-2" style="font-size: 15px"></i>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
