<ngx-ui-loader>
    <p>Loading...</p>
</ngx-ui-loader>

<div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5" style="min-height:0px">
        <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark" tooltip="">Sync</span>
        </h3>
        <div class="card-toolbar">
            <button class="btn btn-light-dark btn-sm font-weight-bolder font-size-sm" (click)="syncUrl()"
                tooltip="{{tooltip}}">
                Sync Url
            </button>
        </div>
    </div>
    <div class="card-body pt-0">
        <!--Searching And Sorting-->
        <div class="table-responsive example overflow-auto">
            <table class="table table-head-custom table-vertical-center" style="width: 1200px">
                <thead id="table-box">
                    <tr class="text-left">
                        <th class="position-sticky top-0 bg-white" style="width: 80px; z-index: 100">Sr. No.</th>
                        <th class="position-sticky top-0 bg-white" style="min-width: 180px; z-index: 100">URL</th>
                    </tr>
                </thead>
                <tbody>
                    <!--  -->
                    <tr *ngFor="let url of urlsData ; let i=index">
                        <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3 cursor">
                                {{i + 1}}
                            </span>
                        </td>
                        <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3 cursor">
                                https://www.tradnary.com/product/description/{{ url }}
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-info btn-sm" (click)="checkStatus(url, i )">Check Status</button>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-sm" (click)="deleteUrl(url)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>