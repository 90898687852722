<div class="footer bg-white py-4 d-flex flex-lg-column">
  <!--Container-->
  <div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
    <!--Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted font-weight-bold mr-2">{{ currentYear }}&copy; All Rights Reserved,
      </span>
      <a href="https://www.tradnary.com" target="_blank" class="uk-text-bold text-dark-75 text-hover-info">Tradnary</a>
    </div>
  </div>
</div>
