import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

/** Dependinces */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { OrderModule } from 'ngx-order-pipe';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

/**Guards */
import { AuthGuard } from './_guard/auth.guard';

/**custom pipe */
import { TimeagoPipe } from './_pipes/timeago.pipe';
import { ProductPipe } from './_pipes/product.pipe';

/** Service */
import { CategoryService } from './_service/category.service';
import { ProductService } from './_service/product.service';
import { AdminService } from './_service/admin.service';
import { TrendingService } from './_service/trending.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './base/header/header.component';
import { FooterComponent } from './base/footer/footer.component';
import { LoginComponent } from './auth/login/login.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategorylistComponent } from './category/categorylist/categorylist.component';
import { AddcategoryComponent } from './category/addcategory/addcategory.component';
import { AddproductComponent } from './product/addproduct/addproduct.component';
import { ProductlistComponent } from './product/productlist/productlist.component';
import { TrendingComponent } from './trending/trending/trending.component';
import { AddtrendingComponent } from './trending/addtrending/addtrending.component';
import { ProductdetailComponent } from './product/productdetail/productdetail.component';
import { UpdateproductComponent } from './product/updateproduct/updateproduct.component';
import { UpdatecategoryComponent } from './category/updatecategory/updatecategory.component';
import { AddstockComponent } from './stock/addstock/addstock.component';
import { StockService } from './_service/stock.service';
import { IndexingUrlComponent } from './indexing-url/indexing-url.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
  declarations: [   
    AppComponent,
    LoginComponent,
    PagenotfoundComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    CategorylistComponent,
    AddcategoryComponent,
    AddproductComponent,
    ProductlistComponent,
    TimeagoPipe,
    TrendingComponent,
    AddtrendingComponent,
    ProductdetailComponent,
    ProductPipe,
    UpdateproductComponent,
    UpdatecategoryComponent,
    AddstockComponent,
    IndexingUrlComponent,
  ],
  imports: [
    TooltipModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    OrderModule,
    FileUploadModule,
    NgxUiLoaderModule.forRoot({
      "bgsColor": "#D1D3E0",
      "bgsOpacity": 0.5,
      "bgsPosition": "bottom-right",
      "bgsSize": 60,
      "bgsType": "ball-spin-clockwise",
      "blur": 5,
      "delay": 0,
      "fastFadeOut": true,
      "fgsColor": "#181C32",
      "fgsPosition": "center-center",
      "fgsSize": 60,
      "fgsType": "three-bounce",
      "gap": 24,
      "logoPosition": "center-center",
      "logoSize": 120,
      "logoUrl": "",
      "masterLoaderId": "master",
      "overlayBorderRadius": "0",
      "overlayColor": "rgba(40, 40, 40, 0.8)",
      "pbColor": "red",
      "pbDirection": "ltr",
      "pbThickness": 3,
      "hasProgressBar": false,
      "text": "",
      "textColor": "#FFFFFF",
      "textPosition": "center-center",
      "maxTime": -1,
      "minTime": 300
    }),
  ],
  providers: [
    AuthGuard,
    CategoryService,
    ProductService,
    AdminService,
    TrendingService,
    StockService
  ],
  bootstrap: [AppComponent],
  entryComponents: [AddcategoryComponent, UpdatecategoryComponent, AddtrendingComponent],
})
export class AppModule { }
