import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, Event } from '@angular/router';
import { filter } from 'rxjs/Operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  heading: string = 'Categories';
  subHeading: string = ' List';
  urlEventResponse: any;

  constructor(private router: Router) {
    this.getUrlOnReload();
    this.getUrlOnChange();
  }

  ngOnInit(): void { }

  getUrlOnReload() {
    if (this.router.url == '/catgory') {
      this.heading = 'Categories';
      this.subHeading = 'List';
    } else if (this.router.url.split('?')[0] == '/products') {
      this.heading = 'Products';
      this.subHeading = 'List';
    } else if (this.router.url.split('?')[0] == '/addProducts') {
      this.heading = 'Products';
      this.subHeading = 'Add New Product';
    } else if (this.router.url.split('?')[0] == '/productDetails') {
      this.heading = 'Product';
      this.subHeading = 'Details';
    } else if (this.router.url == '/trending') {
      this.heading = 'Trending';
      this.subHeading = 'List';
    }
  }

  getUrlOnChange() {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: Event) => {
        this.urlEventResponse = event;
        if (this.urlEventResponse.url == '/') {
          this.heading = 'Categories';
          this.subHeading = 'List';
        } else if (this.urlEventResponse.url.split('?')[0] == '/products') {
          this.heading = 'Products';
          this.subHeading = 'List';
        } else if (this.urlEventResponse.url.split('?')[0] == '/addProduct') {
          this.heading = 'Products';
          this.subHeading = 'Add New Product';
        } else if (
          this.urlEventResponse.url.split('?')[0] == '/productDetails'
        ) {
          this.heading = 'Product';
          this.subHeading = 'Details';
        } else if (this.urlEventResponse.url == '/trending') {
          this.heading = 'Trending';
          this.subHeading = 'List';
        }
      });
  }
}
