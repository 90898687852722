import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostExplorer } from 'aws-sdk';

import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/_service/product.service';

@Component({
  selector: 'app-updateproduct',
  templateUrl: './updateproduct.component.html',
  styleUrls: ['./updateproduct.component.css']
})
export class UpdateproductComponent implements OnInit {
  formGroup: FormGroup
  getProductDetailsResponse: any;
  getProductDetailsResponseData: any;
  formData: FormData = new FormData();
  productId: string
  buttonTitle: string = 'Update';
  submit: boolean = false;
  image: Array<File> = [];
  thumbnail: any;
  updateProductResponse: any;
  videoFormData: FormData = new FormData();
  videos: Array<File> = [];
  videoError: string = ""
  buttonSpinner: boolean = false
  animation: boolean = true;
  multiple: boolean = true;
  categoryId: string = "";
  titleCount: number = 0;
  descriptionCount: number = 0;
  statusTitle: string = "";
  statusDescription: string = "";
  private filesControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(5),
  ]);
  constructor(
    private productService: ProductService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService) {
    this.getQueryParams();
    this.formValidation();
  }

  ngOnInit(): void {
  }

  /**Add Product Form Validation */
  formValidation() {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
      size: new FormControl(''),
      material: new FormControl(''),
      sellPrice: new FormControl(''),
      buyPrice: new FormControl(''),
      status: new FormControl(''),
      benefits: new FormControl(''),
      useFor: new FormControl(''),
      keywords: new FormControl(''),
      history: new FormControl(''),
      strangeStory: new FormControl(''),
      link: new FormControl(''),
      metaTitle: new FormControl(''),
      metaDescription: new FormControl(''),
       url: new FormControl(''),
      image: this.filesControl,
      videos: this.filesControl
    });
  }

  /**Get Product Form Controls */
  get formControls() {
    return this.formGroup.controls;
  }


  /**Get query params */
  getQueryParams() {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        this.productId = params.productId;
        this.categoryId = params.categoryId
        console.log(params.categoryId)
        this.getProductDetails(this.productId);
      })
  }

  /**Get product details */
  getProductDetails(productId) {
    this.productService.getProductDetails(productId)
      .subscribe((response) => {
        this.getProductDetailsResponse = response;
        if (this.getProductDetailsResponse.err == 0) {
          this.getProductDetailsResponseData = this.getProductDetailsResponse.product
          this.productSetter(this.getProductDetailsResponseData);
        }
      })
  }
  blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: "image/png" })
  }
  async getThumbnailForVideo(file) {
    const videoUrl = URL.createObjectURL(file);
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";

    // Trigger video load
    await new Promise((resolve, reject) => {
      video.addEventListener("loadedmetadata", () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Seek the video to 25%
        video.currentTime = video.duration * 0.25;
      });
      video.addEventListener("seeked", () => resolve(""));
      video.src = videoUrl;
    });
    // Draw the thumbnailz
    canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

    var myFile = canvas.toDataURL("image/png");
    const date = new Date();
    this.thumbnail = this.blobToFile(myFile, `untitled-${date.getMilliseconds()}`)
  }
  /**Set product value in product form  */
  productSetter(product) {  
    console.log(product);
    
    this.formGroup.get('title').setValue(product.title)
    this.formGroup.get('material').setValue(product.material);
    this.formGroup.get('size').setValue(product.size);
    this.formGroup.get('status').setValue(product.status);
    this.formGroup.get('sellPrice').setValue(product.sellPrice);
    this.formGroup.get('buyPrice').setValue(product.buyPrice);
    this.formGroup.get('link').setValue(product.link);
    this.formGroup.get('keywords').setValue(product.keywords);
    this.formGroup.get('description').setValue(product.description);
    this.formGroup.get('useFor').setValue(product.useFor);
    this.formGroup.get('benefits').setValue(product.benefits);
    this.formGroup.get('strangeStory').setValue(product.strangeStory);
    this.formGroup.get('history').setValue(product.history);
    this.formGroup.get('metaTitle').setValue(product.metaTitle);
    this.formGroup.get('metaDescription').setValue(product.metaDescription);
    this.formGroup.get('url').setValue(product.url);
  }


  /**update an existing product */
  updateProduct() {
    console.warn('i am in update product method')
    this.buttonTitle = 'Updating...';
    this.formData.append('productId', this.productId);
    this.formData.append('title', this.formGroup.value.title);
    this.formData.append('material', this.formGroup.value.material);
    this.formData.append('size', this.formGroup.value.size);
    this.formData.append('status', this.formGroup.value.status);
    this.formData.append('sellPrice', this.formGroup.value.sellPrice);
    this.formData.append('buyPrice', this.formGroup.value.buyPrice);
    this.formData.append('link', this.formGroup.value.link);
    this.formData.append('keywords', this.formGroup.value.keywords);
    this.formData.append('description', this.formGroup.value.description);
    this.formData.append('useFor', this.formGroup.value.useFor);
    this.formData.append('benefits', this.formGroup.value.benefits);
    this.formData.append('strangeStory', this.formGroup.value.strangeStory);
    this.formData.append('history', this.formGroup.value.history);
    this.formData.append('metaTitle', this.formGroup.value.metaTitle);
    this.formData.append('metaDescription', this.formGroup.value.metaDescription);
    // this.formData.append('url', this.formGroup.value.url);
    for (let counter = 0; counter < this.image.length; counter++) {
      if (this.image[counter].type.match('video')) {
        this.getThumbnailForVideo(this.image[counter]);
        this.formData.append('video', this.image[counter]);
        this.formData.append('thumbnail', this.thumbnail);
      }
      else {
        this.formData.append('image', this.image[counter]);
      }
    }
    this.productService.updateProduct(this.formData)
      .subscribe((response) => {
        this.updateProductResponse = response;
        if (this.updateProductResponse.err == 0) {
          this.submitUrl()
          this.buttonTitle = 'Update';
          this.formGroup.reset();
          this.submit = false;
          this.router.navigate([`/products`], { queryParams: { categoryId: this.categoryId } });
          this.toastrService.success(`${this.updateProductResponse.msg}`);
        } else {
          this.buttonTitle = 'Update';
          this.toastrService.error(`${this.updateProductResponse.msg}`);
        }
      });
  }


  async uploadVideo() {
    if (this.videos.length === 0) {
      this.videoError = "Upload at least on video!"
      return
    }
    else {
      this.buttonSpinner = true;
      this.videoFormData.append('productId', this.productId);
      for (let counter = 0; counter < this.videos.length; counter++) {
        if (this.videos[counter].type.match('video')) {
          await this.getThumbnailForVideo(this.videos[counter]);
          this.videoFormData.append('video', this.videos[counter]);
          this.videoFormData.append('thumbnail', this.thumbnail);
        }
      }
      this.productService.uploadVideo(this.videoFormData).subscribe((response) => {
        this.updateProductResponse = response
        if (this.updateProductResponse?.err === false) {
          this.buttonSpinner = false;
          this.toastrService.success("Video Uploaded Successfully");
        } else {
          this.buttonSpinner = false;
          this.toastrService.error("Something went wrong!");
        }
      })
    }
  }


  async submitUrl() {
    var re = /,?[ ]/g;
    var subst = '-';
    const data = {
      "metaUrl": this.formGroup.value.metaTitle.replace(re, subst).toLocaleLowerCase()
    }
    await this.productService.submitUrls(data).subscribe((response) => {
      console.log(response)
    });
  }

  handleMetaTitle(event) {
    if (event?.length <= 60 && event?.length === 60) {
      this.statusTitle = "success"
    } else if (event?.length > 60) {
      this.statusTitle = "warning"
    } else {
      this.statusTitle = ""
    }

    this.titleCount = event.length
  }
  handleMetaDescription(event) {
    if (event?.length <= 160 && event?.length === 160) {
      this.statusDescription = "success"
    } else if (event?.length > 160) {
      this.statusDescription = "warning"
    } else {
      this.statusDescription = ""
    }
    this.descriptionCount = event.length
  }
}
