<ngx-ui-loader>
  <p>Loading...</p>
</ngx-ui-loader>

<div class="card card-custom card-stretch gutter-b">
  <div class="card-header border-0 py-5" style="min-height:0px">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Products</span>
    </h3>
    <div class="card-toolbar">
      <button [hidden]="addProdBtnState" routerLink="/addProduct"
        class="btn btn-light-dark btn-sm font-weight-bolder font-size-sm"
        [queryParams]="{ categoryId: product?.categoryId }">
        Add New Product
      </button>
    </div>
  </div>
  <div class="card-body pt-0">
    <!--Searching And Sorting-->
    <div>
      <div class="row align-items-center">
        <div class="col-lg-12 col-xl-12">
          <div class="row align-items-center">
            <div class="col-md-3 my-1 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Catogery:</label>
                <div class="dropdown bootstrap-select form-control mb-0 p-0 border-0 h-auto">
                  <select class="form-control m-0" [(ngModel)]="categoryId">
                    <option [value]="undefined" selected disabled>select categroy</option>
                    <option *ngFor="let category of categoryResponseData" [value]="category._id">
                      {{ category.name }}
                    </option>
                  </select>
                  <div class="dropdown-menu">
                    <div class="inner show" role="listbox" tabindex="-1">
                      <ul class="dropdown-menu inner show" role="presentation"></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 my-1 my-md-0">
              <div class="input-icon">
                <input type="text" class="form-control" [(ngModel)]="query" placeholder="Search..." />
                <span>
                  <i class="la la-search" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive example overflow-auto">
      <table class="table table-head-custom table-vertical-center" style="width: 1200px">
        <thead id="table-box">
          <tr class="text-left">
            <th class="position-sticky top-0 bg-white" style="min-width: 180px; z-index: 100">Title</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 100px; z-index: 100">Style #</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 85px">Buy Price</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 85px">Sell price</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 85px">Size</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 85px">Material</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 85px">Status</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 120px; z-index: 1000">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of getProductsResponseData| orderBy: expression:reverse| product: query:categoryId"
            style="height: 75px">
            <td class="datatable-cell">
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40 symbol-sm flex-shrink-0">
                    <img class="" src="{{ product?.image[0] }}" alt="photo" loading="lazy" />
                  </div>
                  <div class="ml-4">
                    <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
                      <a routerLink="/productDetails" [queryParams]="{ productId: product?._id }"
                        class="text-dark-75 font-weight-bolder text-hover-info cursor-pointer">
                        {{ product?.title == "undefined" ? 'N/A': product?.title }}
                      </a>
                    </div>
                  </div>
                </div>
              </span>
            </td>
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3 cursor">
                style#{{ product?.articleId }}
              </span>
            </td>
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3 cursor">
                {{ product?.buyPrice == "undefined" ? 'N/A' : product?.buyPrice }}
              </span>
            </td>
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3">
                {{ product?.sellPrice == "undefined" ? 'N/A': product?.sellPrice }}
              </span>
            </td>
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3">
                {{ product?.size == "undefined" ? 'N/A': product?.size}}
              </span>
            </td>
            <td>
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3">
                {{ product?.material == "undefined" ? 'N/A': product?.material}}
              </span>
            </td>
            <td>
              <span *ngIf="product?.status == 'active'"
                class="label label-lg font-weight-bold label-light-success label-inline">{{ product?.status == "undefined" ? 'N/A': product?.status }}</span>
              <span *ngIf="product?.status == 'block'"
                class="label label-lg font-weight-bold label-light-danger label-inline">{{ product?.status == "undefined" ? 'N/A': product?.status }}</span>
            </td>
            <td class="datatable-cell">
              <span class="d-flex align-items-center" style="overflow: visible; position: relative; width: 188px">
                <a routerLink="/productDetails" [queryParams]="{ productId: product?._id }"
                  class="btn btn-sm btn-primary btn-icon">
                  <i class="la la-eye"></i>
                </a>
                <a routerLink="/updateProduct" [queryParams]="{ productId: product?._id , categoryId: product?.categoryId}"
                  class="btn btn-sm btn-warning btn-icon ml-2">
                  <i class="la la-edit"></i>
                </a>
                <a *ngIf="product?.status == 'active'" class="btn btn-sm btn-danger btn-icon ml-2"
                  (click)="blockUnblockProduct(product?._id, 'block')">
                  <i class="la la-ban"></i>
                </a>
                <a *ngIf="product?.status == 'block'" class="btn btn-sm btn-success btn-icon ml-2"
                  (click)="blockUnblockProduct(product?._id, 'active')">
                  <i class="la la-unlock"></i>
                </a>
                <a class="btn btn-sm btn-danger btn-icon ml-2" (click)="deleteProduct(product?._id)">
                  <i class="la la-trash"></i>
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
