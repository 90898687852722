import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { TrendingService } from 'src/app/_service/trending.service';

@Component({
  selector: 'app-addtrending',
  templateUrl: './addtrending.component.html',
  styleUrls: ['./addtrending.component.css'],
})
export class AddtrendingComponent implements OnInit {
  multiple: boolean = false;
  animation: boolean = true;
  image: Array<File> = [];
  formGroup: FormGroup;
  submit: boolean = false;
  formData: FormData = new FormData();
  addTrendingResponse: any;
  buttonTitle: string = 'Save';

  constructor(
    private bsModalRef: BsModalRef,
    private trendingService: TrendingService,
    private toastrService: ToastrService) {
    this.validation();
  }

  ngOnInit(): void { }

  /**Trending Form Validation */
  validation() {
    this.formGroup = new FormGroup({
      image: new FormControl(null, [Validators.required, Validators.maxLength(1)]),
    });
  }

  /**Get Form Controls  */
  get formControls() {
    return this.formGroup.controls;
  }

  /**Add New Trending */
  addTrending() {
    this.submit = true;
    if (this.formGroup.invalid) {
      return;
    } else {
      this.buttonTitle = 'Saving...';
      this.formData.append('image', this.image[0]);
      this.trendingService.addTrending(this.formData)
        .subscribe((response) => {
          this.addTrendingResponse = response;
          if (this.addTrendingResponse.err == 0) {
            this.trendingService.updateTrendingState(true);
            this.toastrService.success(`${this.addTrendingResponse.msg}`);
            setTimeout(() => { this.closeModal(); }, 800);
          } else {
            this.buttonTitle = 'Save';
            this.toastrService.error(`${this.addTrendingResponse.msg}`);
          }
        });
    }
  }

  /**Close Trending Modal */
  closeModal() {
    this.bsModalRef.hide();
  }
}
