import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrendingService {
  APIURL = environment.APIURL;
  trendingState = new BehaviorSubject<boolean>(false);
  trendingRefreshState = this.trendingState.asObservable();

  constructor(private httpClient: HttpClient) { }

  /** Add new trending */
  addTrending(trending) {
    return this.httpClient.post(`${this.APIURL}addTrending`, trending);
  }

  /** Get trendings */
  getTrendings() {
    return this.httpClient.get(`${this.APIURL}getTrendings`);
  }

  /**Delete Trending */
  deleteTrending(trendingId) {
    return this.httpClient.delete(`${this.APIURL}deleteTrending`, { params: { trendingId: trendingId } });
  }

  /**Update trending State Value For Refresh Component When Add New trending Addded */
  updateTrendingState(value) {
    this.trendingState.next(value);
  }
}
