<div class="card card-custom card-stretch gutter-b">
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">
        Product Details
      </span>
    </h3>
    <div class="card-toolbar">
      <a class="font-weight-bolder font-size-sm"> </a>
    </div>
  </div>
  <div class="card-body pt-0">
    <!--begin::Table-->
    <div class="resize-none">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Title</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.title }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Material</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.material }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Size</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.size }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Stauts</h3>
            <p class="font-size-h6-lg border p-2 rounded"> {{ product?.status }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Sell Price</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.sellPrice }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Buy Price</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.buyPrice }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Supplier</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.link }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Keywords</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.keywords }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Description</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.description }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Use For</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.useFor }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Benefits</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.benefits }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Strange Story</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.strangeStory }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">History</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.history }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">URL</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.url }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Meta Title</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.metaTitle }}</p>
          </div>
          <div class="col-md-4 p-5">
            <h3 class="font-weight-bolder">Meta Description</h3>
            <p class="font-size-h6-lg border p-2 rounded">{{ product?.metaDescription }}</p>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="d-flex justify-content-start align-self-center flex-wrap">
                <div *ngFor="let image of product?.image" class="w-200px h-200px position-relative m-5 overflow-hidden">
                  <div class="position-absolute top-0 left-0 w-100 h-100">
                    <img src="{{ image }}" style="width: 100%; height: 100%; object-fit: cover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <video controls poster="{{product?.thumbnails}}" src="{{product?.videos}}" class="w-100 h-100">
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>