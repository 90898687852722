<div class="card card-custom card-stretch gutter-b">
  <!--Header-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Add New Product</span>
    </h3>
    <div class="card-toolbar"></div>
  </div>
  <hr />
  <!--Body-->
  <div class="card-body pt-0">
    <!--Table-->
    <div class="resize-none">
      <form [formGroup]="formGroup" (submit)="updateProduct()">
        <div class="form-row">
          <div class="col-md-3">
            <div class="form-group">
              <label title="Title">Title</label>
              <input type="text" class="form-control" name="title" placeholder="Enter Product Title"
                formControlName="title" [ngClass]="{'is-invalid': submit && formControls.title.errors}" />
              <div *ngIf="submit && formControls.title.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.title.errors.required">Required Title.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="pmaterial">Material</label>
              <input type="text" class="form-control" name="material" placeholder="Enter product Material"
                formControlName="material" [ngClass]="{'is-invalid': submit && formControls.material.errors}" />
              <div *ngIf="submit && formControls.material.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.material.errors.required">Required Material.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="psize">Size</label>
              <input type="text" class="form-control" name="size" placeholder="Enter product Size"
                formControlName="size" [ngClass]="{'is-invalid': submit && formControls.size.errors}" />
              <div *ngIf="submit && formControls.size.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.size.errors.required">Required Material.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="pstatus">Status</label>
              <select class="form-control" name="status" formControlName="status"
                [ngClass]="{'is-invalid': submit && formControls.status.errors}">
                <option value="undefined" disabled>Select Status</option>
                <option value="active">Active</option>
                <option value="block">Block</option>
              </select>
              <div *ngIf="submit && formControls.status.errors" class="invalid-feedback">
                <div *ngIf="formControls.status.errors.required">Required Status.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="form-group">
              <label title="psalePrice">Sell Price</label>
              <input type="text" class="form-control" name="sellPrice" placeholder="Enter Sell Price"
                formControlName="sellPrice" [ngClass]="{'is-invalid': submit && formControls.sellPrice.errors}" />
              <div *ngIf="submit && formControls.sellPrice.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.sellPrice.errors.required">Required Sell Price.</div>
                <div *ngIf="formControls.sellPrice.errors.pattern">Required Valid Amount.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="Buy Price">Buy Price</label>
              <input type="text" class="form-control" placeholder="Enter Buy Price" name="buyPrice"
                formControlName="buyPrice" [ngClass]="{'is-invalid': submit && formControls.buyPrice.errors}" />
              <div *ngIf="submit && formControls.buyPrice.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.buyPrice.errors.required">Required Buy Price.</div>
                <div *ngIf="formControls.buyPrice.errors.pattern">Required Valid Amount.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="Supplier">Supplier</label>
              <input type="text" class="form-control" placeholder="Enter Link" name="link" formControlName="link"
                [ngClass]="{'is-invalid': submit && formControls.link.errors}" />
              <div *ngIf="submit && formControls.link.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.link.errors.required">Required Link. </div>
                <div *ngIf="formControls.link.errors.pattern">Required Valid Link.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="Keywords">Keywords</label>
              <input type="text" class="form-control" placeholder="Enter Keywords" name="keywords"
                formControlName="keywords" [ngClass]="{'is-invalid': submit && formControls.keywords.errors}" />
              <div *ngIf="submit && formControls.keywords.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.keywords.errors.required">Required Keywords.</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label title="meta-title">Meta Title</label>
              <textarea type="text" class="form-control" name="metaTitle" placeholder="Write Something About Product..."
              [ngClass]="statusTitle==='success' ? 'bg-success-subtle' : statusTitle==='warning'? 'bg-warning-subtle' : ''"
              (ngModelChange)="handleMetaTitle($event)" maxlength="70"
                rows="3" formControlName="metaTitle" [ngClass]="{'is-invalid': submit && formControls.metaTitle.errors}">
                        </textarea>
              <div *ngIf="submit && formControls.metaTitle.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.metaTitle.errors.required">Required Meta Title.</div>
              </div>
              <div> <span
                  [ngClass]="statusTitle==='success' ? 'text-success' : statusTitle==='warning'? 'text-danger' : ''">{{titleCount}}</span>
                out of 70 max recommended characters.</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label title="meta-description">Meta Description</label>
              <textarea type="text" class="form-control" name="metaDescription" placeholder="Write Something About Product..."
               [ngClass]="statusDescription==='success' ? 'bg-success-subtle' : statusDescription==='warning'? 'bg-warning-subtle' : ''"
               (ngModelChange)="handleMetaDescription($event)" maxlength="170"
                rows="3" formControlName="metaDescription" [ngClass]="{'is-invalid': submit && formControls.metaDescription.errors}">
                        </textarea>
              <div *ngIf="submit && formControls.metaDescription.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.metaDescription.errors.required">Required Meta Description.</div>
              </div>
              <div> <span
                  [ngClass]="statusDescription==='success' ? 'text-success' : statusDescription==='warning'? 'text-danger' : ''">{{descriptionCount}}</span>
                out of 170 max recommended characters.</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="meta-url">URL</label>
              <textarea type="text" class="form-control" name="url" formControlName="url" disabled>           
                </textarea>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4">
            <div class="form-group">
              <label title="Description">Description</label>
              <textarea type="text" class="form-control" name="description"
                placeholder="Write Something About Product..." rows="6" formControlName="description"
                [ngClass]="{'is-invalid': submit && formControls.description.errors}">
              </textarea>
              <div *ngIf="submit && formControls.description.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.description.errors.required">Required Description.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="Use For">Use For</label>
              <textarea class="form-control" name="useFor" rows="6" placeholder="Write Some Idle Of Product..."
                formControlName="useFor" [ngClass]="{'is-invalid': submit && formControls.useFor.errors}"></textarea>
              <div *ngIf="submit && formControls.useFor.errors" class="invalid-feedback">
                <div *ngIf="formControls.useFor.errors.required">Required Use For.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="pbenifits">Benefits</label>
              <textarea class="form-control" name="benefits" rows="6"
                placeholder="Write Something About Benefits Of Product..." formControlName="benefits"
                [ngClass]="{'is-invalid': submit && formControls.benefits.errors}"></textarea>
              <div *ngIf="submit && formControls.benefits.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.benefits.errors.required">Required Benefits.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4">
            <div class="form-group">
              <label title="Strange Story">Strange Story</label>
              <textarea class="form-control" name="strangeStory" rows="6"
                placeholder="Write Something On Product Story... " formControlName="strangeStory"
                [ngClass]="{'is-invalid': submit && formControls.strangeStory.errors}"></textarea>
              <div *ngIf="submit && formControls.strangeStory.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.strangeStory.errors.required">Required Strange Story.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="History">History</label>
              <textarea class="form-control" name="history" rows="6" placeholder="Write Something On Product History..."
                formControlName="history" [ngClass]="{'is-invalid': submit && formControls.history.errors}"></textarea>
              <div *ngIf="submit && formControls.history.errors" class="invalid-feedback">
                <div *ngIf="formControls.history.errors.required">Required History.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="History">Videos</label>
              <file-upload name="videos" [(ngModel)]="videos" formControlName="videos" [multiple]="multiple"
                [animation]="animation"></file-upload>           
              <div *ngIf="videoError !== ''" class="invalid-feedback d-block">{{videoError}}</div>
            </div>
          </div>
        </div>
        <hr />
        <button type="submit" class="btn btn-dark float-right" [disabled]="buttonTitle == 'Updating...'">
          {{ buttonTitle }}
        </button>

        <button *ngIf="!buttonSpinner" type="button" class="btn btn-dark float-right mr-3" (click)="uploadVideo()">
          Upload Video
        </button>
        <ng-template #elseSpan>
          <button type="button" class="btn btn-dark float-right mr-3" style="min-width: 105.84px;">
            <i class="la la-spinner la-spin p-0"></i>
          </button>
        </ng-template>
      </form>
    </div>
  </div>
</div>