import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  APIURL = environment.APIURL;

  constructor(private httpClient: HttpClient) {}

  /** Admin Signin */
  signin(admin) {
    return this.httpClient.post(`${this.APIURL}adminLogin`, admin);
  }
}
