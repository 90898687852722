import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { StockService } from '../../_service/stock.service';

import { Stock } from '../../_model/stock';

@Component({
  selector: 'app-addstock',
  templateUrl: './addstock.component.html',
  styleUrls: ['./addstock.component.css'],
})
export class AddstockComponent implements OnInit {
  formData: FormData = new FormData();
  formGroup: FormGroup;
  submit: boolean = false;
  image: Array<File> = [];
  stock: Stock = new Stock();
  buttonTitle: string = 'Save';
  addStockResponse: any;
  multiple: boolean = true;
  animation: boolean = true;

  private filesControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(5),
  ]);

  constructor(
    private toastrService: ToastrService,
    private stockService: StockService,
    private router: Router) {
    this.formValidation();
  }

  ngOnInit(): void { }

  /**Add Product Form Validation */
  formValidation() {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
      size: new FormControl(''),
      image: this.filesControl,
      material: new FormControl(''),
      sellPrice: new FormControl(''),
      buyPrice: new FormControl(''),
      status: new FormControl(''),
      benefits: new FormControl(''),
      useFor: new FormControl(''),
      keywords: new FormControl(''),
      history: new FormControl(''),
      strangeStory: new FormControl(''),
      link: new FormControl(''),
    });
  }

  /**Get Product Form Controls */
  get formControls() {
    return this.formGroup.controls;
  }

  /**add Product */
  addStock() {
    this.submit = true;
    if (this.formGroup.invalid) {
      return;
    } else {
      this.buttonTitle = 'Saving...';
      this.formData.append('title', this.stock.title);
      this.formData.append('material', this.stock.material);
      this.formData.append('size', this.stock.size);
      this.formData.append('status', this.stock.status);
      this.formData.append('sellPrice', this.stock.sellPrice);
      this.formData.append('buyPrice', this.stock.buyPrice);
      this.formData.append('link', this.stock.link);
      this.formData.append('keywords', this.stock.keywords);
      this.formData.append('description', this.stock.description);
      this.formData.append('useFor', this.stock.useFor);
      this.formData.append('benefits', this.stock.benefits);
      this.formData.append('strangeStory', this.stock.strangeStory);
      this.formData.append('history', this.stock.history);
      for (let counter = 0; counter < this.image.length; counter++) {
        this.formData.append('image', this.image[counter]);
      }
      this.stockService.addStock(this.formData)
        .subscribe((response) => {
          this.addStockResponse = response;
          if (this.addStockResponse.err == 0) {
            this.buttonTitle = 'Save';
            this.formGroup.reset();
            this.filesControl.setValue([]);
            this.submit = false;
            this.router.navigate(['/products']);
            this.toastrService.success(`${this.addStockResponse.msg}`);
          } else {
            this.buttonTitle = 'Save';
            this.toastrService.error(`${this.addStockResponse.msg}`
            );
          }
        });
    }
  }

}
