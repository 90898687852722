<div class="card card-custom card-stretch gutter-b">
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Catogories</span>
    </h3>
    <div class="card-toolbar">
      <a (click)="addCategoryModal()" class="btn btn-light-dark btn-sm font-weight-bolder font-size-sm">Add New Catogory
      </a>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive example overflow-auto">
      <table id="kt_advance_table_widget_1" class="table table-head-custom table-vertical-center" style="width: 1200px">
        <thead id="table-box">
          <tr class="text-left">
            <th class="position-sticky top-0 bg-white" style="min-width: 180px; z-index: 100">Name</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 250px">Description</th>
            <th class="position-sticky top-0 bg-white" style="min-width: 100px">Create at</th>
            <th class="pr-0 text-center position-sticky top-0 bg-white d-none" style="min-width: 80px; z-index: 1000">
              Status</th>
            <th class="pr-0 text-center position-sticky top-0 bg-white" style="min-width: 150px; z-index: 1000">action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let category of categoriesResponseData| orderBy: expression:reverse">
            <td class="datatable-cell">
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40 symbol-sm flex-shrink-0">
                    <img class="" src="{{ category?.image }}" alt="photo" />
                  </div>
                  <div class="ml-4">
                    <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
                      <a routerLink="/products" [queryParams]="{ categoryId: category?._id }"
                        class="text-dark-75 font-weight-bolder text-hover-info">
                        {{ category?.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </span>
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg text-justify mt-3">{{ category?.description }}</span>
            </td>
            <td>
              <span class="text-dark-75 font-weight-bold text-hover-primary font-size-lg">
                {{ category?.timeStamp | timeago }}
              </span>
            </td>
            <td class="datatable-cell d-none">
              <span
                class="label label-lg font-weight-bold label-light-primary label-inline">{{ category?.status }}</span>
            </td>
            <td>
              <div class="d-flex justify-content-center align-items-center">
                <span class="switch switch-sm switch-outline switch-dark">
                  <label>
                    <input type="checkbox" name="select" [checked]="category?.status == 'active'"
                      (change)="categoryBlockUnblock($event.target.checked,category?._id)" />
                    <span> </span>
                  </label>
                </span>
                <a (click)="editCategory(category)" class="btn btn-sm btn-warning btn-icon ml-2">
                  <i class="la la-edit"></i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
