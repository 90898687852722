<div class="modal-content">
  <!--Header-->
  <div class="modal-header">
    <h5 class="modal-title">Add New Trending</h5>
    <button type="button" class="close" (click)="closeModal()">&times;</button>
  </div>
  <!--Body-->
  <div class="modal-body">
    <!--Form-->
    <form [formGroup]="formGroup" (submit)="addTrending()">
      <!--Drag And Drop For Image-->
      <div class="form-row">
        <div class="col-md-12 cursor-pointer">
          <label>Attach trending</label>
          <file-upload name="image" [(ngModel)]="image" formControlName="image" [multiple]="multiple"
            [animation]="animation" accept="image/*"></file-upload>
          <div *ngIf="submit && formControls.image.errors" class="invalid-feedback d-block">
            <div *ngIf="formControls.image.errors.required">Required trending Attachement.</div>
            <div *ngIf="formControls.image.errors.fileTypes">Please Attachement Only Image.</div>
          </div>
        </div>
      </div>
      <hr />
      <!--Form Submit Button-->
      <button type="submit" class="btn btn-dark float-right" [disabled]="buttonTitle == 'Saving...'">
        {{ buttonTitle }}
      </button>
    </form>
  </div>
</div>
