<div class="card card-custom card-stretch gutter-b">
  <!--Header-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Add New Stock</span>
    </h3>
    <div class="card-toolbar"></div>
  </div>
  <hr />
  <!--Body-->
  <div class="card-body pt-0">
    <!--Table-->
    <div class="resize-none">
      <form [formGroup]="formGroup" (submit)="addStock()">
        <div class="form-row">
          <div class="col-md-3">
            <div class="form-group">
              <label title="Title">Title</label>
              <input type="text" class="form-control" name="title" placeholder="Enter Product Title"
                [(ngModel)]="stock.title" formControlName="title"
                [ngClass]="{'is-invalid': submit && formControls.title.errors}" />
              <div *ngIf="submit && formControls.title.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.title.errors.required">Required Title.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="pmaterial">Material</label>
              <input type="text" class="form-control" name="material" placeholder="Enter product Material"
                [(ngModel)]="stock.material" formControlName="material"
                [ngClass]="{'is-invalid': submit && formControls.material.errors}" />
              <div *ngIf="submit && formControls.material.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.material.errors.required">Required Material.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="psize">Size</label>
              <input type="text" class="form-control" name="size" placeholder="Enter product Size"
                [(ngModel)]="stock.size" formControlName="size"
                [ngClass]="{'is-invalid': submit && formControls.size.errors}" />
              <div *ngIf="submit && formControls.size.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.size.errors.required">Required Material.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="pstatus">Status</label>
              <select class="form-control" name="status" [(ngModel)]="stock.status" formControlName="status"
                [ngClass]="{'is-invalid': submit && formControls.status.errors}">
                <option value="undefined" disabled>Select Status</option>
                <option value="active">Active</option>
                <option value="block">Block</option>
              </select>
              <div *ngIf="submit && formControls.status.errors" class="invalid-feedback">
                <div *ngIf="formControls.status.errors.required">Required Status.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="form-group">
              <label title="psalePrice">Sell Price</label>
              <input type="text" class="form-control" name="sellPrice" placeholder="Enter Sell Price"
                [(ngModel)]="stock.sellPrice" formControlName="sellPrice"
                [ngClass]="{'is-invalid': submit && formControls.sellPrice.errors}" />
              <div *ngIf="submit && formControls.sellPrice.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.sellPrice.errors.required">Required Sell Price.</div>
                <div *ngIf="formControls.sellPrice.errors.pattern">Required Valid Amount.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="Buy Price">Buy Price</label>
              <input type="text" class="form-control" placeholder="Enter Buy Price" name="buyPrice"
                [(ngModel)]="stock.buyPrice" formControlName="buyPrice"
                [ngClass]="{'is-invalid': submit && formControls.buyPrice.errors}" />
              <div *ngIf="submit && formControls.buyPrice.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.buyPrice.errors.required">Required Buy Price.</div>
                <div *ngIf="formControls.buyPrice.errors.pattern">Required Valid Amount.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="Link">Link</label>
              <input type="text" class="form-control" placeholder="Enter Link" name="link" [(ngModel)]="stock.link"
                formControlName="link" [ngClass]="{'is-invalid': submit && formControls.link.errors}" />
              <div *ngIf="submit && formControls.link.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.link.errors.required">Required Link.</div>
                <div *ngIf="formControls.link.errors.pattern">Required Valid Link.</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label title="Keywords">Keywords</label>
              <input type="text" class="form-control" placeholder="Enter Keywords" name="keywords"
                [(ngModel)]="stock.keywords" formControlName="keywords"
                [ngClass]="{'is-invalid': submit && formControls.keywords.errors}" />
              <div *ngIf="submit && formControls.keywords.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.keywords.errors.required">Required Keywords.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4">
            <div class="form-group">
              <label title="Description">Description</label>
              <textarea type="text" class="form-control" name="description"
                placeholder="Write Something About Product..." rows="6" [(ngModel)]="stock.description"
                formControlName="description" [ngClass]="{'is-invalid': submit && formControls.description.errors}">
                </textarea>
              <div *ngIf="submit && formControls.description.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.description.errors.required">Required Description.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="Use For">Use For</label>
              <textarea class="form-control" name="useFor" rows="6" placeholder="Write Some Idle Of Product..."
                [(ngModel)]="stock.useFor" formControlName="useFor"
                [ngClass]="{'is-invalid': submit && formControls.useFor.errors}"></textarea>
              <div *ngIf="submit && formControls.useFor.errors" class="invalid-feedback">
                <div *ngIf="formControls.useFor.errors.required">Required Use For.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="pbenifits">Benefits</label>
              <textarea class="form-control" name="benefits" rows="6"
                placeholder="Write Something About Benefits Of Product..." [(ngModel)]="stock.benefits"
                formControlName="benefits"
                [ngClass]="{'is-invalid': submit && formControls.benefits.errors}"></textarea>
              <div *ngIf="submit && formControls.benefits.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.benefits.errors.required">Required Benefits.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4">
            <div class="form-group">
              <label title="Strange Story">Strange Story</label>
              <textarea class="form-control" name="strangeStory" rows="6"
                placeholder="Write Something On Product Story... " [(ngModel)]="stock.strangeStory"
                formControlName="strangeStory"
                [ngClass]="{'is-invalid': submit && formControls.strangeStory.errors}"></textarea>
              <div *ngIf="submit && formControls.strangeStory.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.strangeStory.errors.required">Required Strange Story.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="History">History</label>
              <textarea class="form-control" name="history" rows="6" placeholder="Write Something On Product History..."
                [(ngModel)]="stock.history" formControlName="history"
                [ngClass]="{'is-invalid': submit && formControls.history.errors}"></textarea>
              <div *ngIf="submit && formControls.history.errors" class="invalid-feedback">
                <div *ngIf="formControls.history.errors.required">Required History.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label title="History">Images</label>
              <file-upload name="image" [(ngModel)]="image" formControlName="image" [multiple]="multiple"
                [animation]="animation" accept="image/*"></file-upload>
              <div *ngIf="submit && formControls.image.errors" class="invalid-feedback d-block">
                <div *ngIf="formControls.image.errors.required">Required Images.</div>
                <div *ngIf="formControls.image.errors.maxlength">Please select maximum 5 images.</div>
                <div *ngIf="formControls.image.errors.minlength">Please select minimum 1 images.</div>
                <div *ngIf="formControls.image.errors.fileTypes">Please select Only Images.</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <button type="submit" class="btn btn-dark float-right" [disabled]="buttonTitle == 'Saving...'">
          {{ buttonTitle }}
        </button>
      </form>
    </div>
  </div>
</div>
