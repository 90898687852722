import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/_service/category.service';

@Component({
  selector: 'app-updatecategory',
  templateUrl: './updatecategory.component.html',
  styleUrls: ['./updatecategory.component.css']
})
export class UpdatecategoryComponent implements OnInit {
  selectedCategory: any;
  buttonTitle: string = "Update";
  formGroup: FormGroup
  submit: boolean = false;
  image: any;
  formData: FormData = new FormData();
  updateCategoryResponse: any;

  constructor(
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    private bsModalRef: BsModalRef) {
    this.updateCategoryFormValidation();
  }

  ngOnInit(): void {
    this.setterCategoryFormValue(this.selectedCategory);
  }

  updateCategoryFormValidation() {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
    });
  }

  /**Get update category form controls */
  get formControls() {
    return this.formGroup.controls;
  }

  /**Set category form values */
  setterCategoryFormValue(category) {
    this.formGroup.get('name').setValue(category.name);
    this.formGroup.get('description').setValue(category.description);
    this.formGroup.get('status').setValue(category.status);
  }

  /** Get Image From Form */
  detectImage(event) {
    if (event.target.files[0] && event.target.files) {
      this.image = event.target.files[0];
    }
  }

  /**Update category */
  updateCategory() {
    this.submit = true;
    if (this.formGroup.invalid) {
      return;
    } else {
      this.buttonTitle = 'Updating...';
      this.formData.append('name', this.formGroup.value.name);
      this.formData.append('description', this.formGroup.value.description);
      this.formData.append('status', this.formGroup.value.status);
      this.formData.append('image', this.image);
      this.categoryService.updateCategory(this.selectedCategory._id, this.formData)
        .subscribe((response) => {
          this.updateCategoryResponse = response;
          if (this.updateCategoryResponse.err == 0) {
            this.categoryService.updateCategoryState(true);
            this.toastrService.success(`${this.updateCategoryResponse.msg}`);
            setTimeout(() => { this.closeModal(); }, 800);
          } else {
            this.buttonTitle = 'Submit';
            this.toastrService.error(`${this.updateCategoryResponse.msg}`);
          }
        });
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
