import { async } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/_model/product';
import { ProductService } from 'src/app/_service/product.service';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css'],
})
export class AddproductComponent implements OnInit {
  formData: FormData = new FormData();
  formGroup: FormGroup;
  submit: boolean = false;
  image: Array<File> = [];
  product: Product = new Product();
  buttonTitle: string = 'Save';
  addProductResponse: any;
  multiple: boolean = true;
  animation: boolean = true;
  thumbnail: any;
  titleCount: number = 0;
  descriptionCount: number = 0;
  statusTitle: string = "";
  statusDescription: string = "";

  private filesControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(5),
  ]);

  constructor(
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private router: Router) {
    this.getQueryParams();
    this.formValidation();
  }

  ngOnInit(): void { }

  /** Get Query Parms */
  getQueryParams() {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        this.product.categoryId = params.categoryId;
      });
  }

  /**Add Product Form Validation */
  formValidation() {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
      size: new FormControl(''),
      image: this.filesControl,
      material: new FormControl(''),
      sellPrice: new FormControl(''),
      buyPrice: new FormControl(''),
      status: new FormControl(''),
      benefits: new FormControl(''),
      useFor: new FormControl(''),
      keywords: new FormControl(''),
      history: new FormControl(''),
      strangeStory: new FormControl(''),
      link: new FormControl(''),
      metaTitle: new FormControl(''),
      metaDescription: new FormControl(''),
    });
  }

  /**Get Product Form Controls */
  get formControls() {
    return this.formGroup.controls;
  }
  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }
  // blobToFile(theBlob, fileName) {
  //   //A Blob() is almost a File() - it's just missing the two properties below which we will add
  //   theBlob.lastModifiedDate = new Date();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }
  blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: "image/png" })
  }
  async getThumbnailForVideo(file) {
    const videoUrl = URL.createObjectURL(file);
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";

    // Trigger video load
    await new Promise((resolve, reject) => {
      video.addEventListener("loadedmetadata", () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Seek the video to 25%
        video.currentTime = video.duration * 0.25;
      });
      video.addEventListener("seeked", () => resolve(""));
      video.src = videoUrl;
    });
    // Draw the thumbnailz
    canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

    var myFile = canvas.toDataURL("image/png");
    const date = new Date();
    this.thumbnail = this.blobToFile(myFile, `untitled-${date.getMilliseconds()}`)
  }
  /**add Product */
  async addProduct() {
    this.submit = true;
    if (this.formGroup.invalid) {
      return;
    } else {
      this.buttonTitle = 'Saving...';
      this.formData.append('categoryId', this.product.categoryId);
      this.formData.append('title', this.product.title);
      this.formData.append('material', this.product.material);
      this.formData.append('size', this.product.size);
      this.formData.append('status', this.product.status);
      this.formData.append('sellPrice', this.product.sellPrice);
      this.formData.append('buyPrice', this.product.buyPrice);
      this.formData.append('link', this.product.link);
      this.formData.append('keywords', this.product.keywords);
      this.formData.append('description', this.product.description);
      this.formData.append('useFor', this.product.useFor);
      this.formData.append('benefits', this.product.benefits);
      this.formData.append('strangeStory', this.product.strangeStory);
      this.formData.append('history', this.product.history);
      this.formData.append('metaTitle', this.product.metaTitle);
      this.formData.append('metaDescription', this.product.metaDescription);
      for (let counter = 0; counter < this.image.length; counter++) {
        if (this.image[counter].type.match('video')) {
          await this.getThumbnailForVideo(this.image[counter]);
          this.formData.append('video', this.image[counter]);
          this.formData.append('thumbnail', this.thumbnail);
        }
        else {
          this.formData.append('image', this.image[counter]);
        }
      }

      this.productService.addProduct(this.formData).subscribe((response) => {
        this.addProductResponse = response;
        if (this.addProductResponse.err == 0) {
          this.submitUrl(this.product.metaTitle)
          this.buttonTitle = 'Save';
          this.formGroup.reset();
          this.filesControl.setValue([]);
          this.submit = false;
          this.toastrService.success(`${this.addProductResponse.msg}`);
        } else {
          this.buttonTitle = 'Save';
          console.log(response)
          this.toastrService.error(`${this.addProductResponse.msg}`);
        }
      });
    }
  }

  async submitUrl(url) {
    var re = /,?[ ]/g;
    var subst = '-';
    const data = {
      "metaUrl": this.product.metaTitle.replace(re, subst).toLocaleLowerCase()
    }
    await this.productService.submitUrls(data).subscribe((response) => {
      console.log(response)
    });
  }

  handleMetaTitle(event) {
    if (event?.length <= 60 && event?.length === 60) {
      this.statusTitle = "success"
    } else if (event?.length > 60) {
      this.statusTitle = "warning"
    } else {
      this.statusTitle = ""
    }

    this.titleCount = event.length
  }
  handleMetaDescription(event) {
    if (event?.length <= 160 && event?.length === 160) {
      this.statusDescription = "success"
    } else if (event?.length > 160) {
      this.statusDescription = "warning"
    } else {
      this.statusDescription = ""
    }
    this.descriptionCount = event.length
  }



}
