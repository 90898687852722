<div class="modal-content">
  <!--Add New Category Open Modal-->
  <div class="modal-header">
    <h5 class="modal-title">Add New Category</h5>
    <button (click)="closeModal()" type="button" class="close" aria-label="Close">
      &times;
    </button>
  </div>
  <!--Add Category Modal Body-->
  <div class="modal-body">
    <!--Form-->
    <form [formGroup]="formGroup" (submit)="addCategory()">
      <!--Category Name-->
      <div class="form-group">
        <label for="Category Name">Name</label>
        <input type="text" class="form-control" name="name" placeholder="Enter category Name"
          [(ngModel)]="category.name" formControlName="name"
          [ngClass]="{'is-invalid': submit && formControls.name.errors}" />
        <div *ngIf="submit && formControls.name.errors" class="invalid-feedback d-block">
          <div *ngIf="formControls.name.errors.required">Required Category Name.</div>
        </div>
      </div>
      <div class="form-row">
        <!--Category Image-->
        <div class="col-md-6">
          <label>Image </label>
          <label for="Caregory Image" class="form-control overflow-hidden">
            <input type="file" name="image" accept=".jpeg, .jpg, .png" readonly (change)="detectImage($event)"
              formControlName="image" [ngClass]="{ 'is-inavalid': submit && formControls.image.errors }" />
          </label>
          <div *ngIf="submit && formControls.image.errors" class="invalid-feedback d-block">
            <div *ngIf="formControls.image.errors.required">Required Category Image.</div>
          </div>
        </div>
        <!--Category Status-->
        <div class="col-md-6">
          <div class="form-group mb-5">
            <label for="Department">Status</label>
            <select class="form-control" formControlName="status" [(ngModel)]="category.status"
              [ngClass]="{ 'is-invalid': submit && formControls.status.errors }">
              <option [ngValue]="undefined" disabled selected>Select Status</option>
              <option value="active">Active</option>
              <option value="block">Block</option>
            </select>
            <div *ngIf="submit && formControls.status.errors" class="invalid-feedback d-block">
              <div *ngIf="formControls.status.errors.required">Required Category Status.</div>
            </div>
          </div>
        </div>
      </div>
      <!--Add Category-->
      <div class="form-group">
        <label for="category Description">Description</label>
        <textarea class="form-control" rows="3" placeholder="Write Something About Category..."
          [(ngModel)]="category.description" formControlName="description"
          [ngClass]="{'is-invalid': submit && formControls.description.errors}"></textarea>
        <div *ngIf="submit && formControls.description.errors" class="invalid-feedback d-block">
          <div *ngIf="formControls.description.errors.required">Required Category Description.</div>
        </div>
      </div>
      <hr />
      <!--Add Category Form Submit Button-->
      <button type="submit" class="btn btn-dark float-right" [disabled]="buttonTitle == 'Submitting...'">
        {{ buttonTitle }}
      </button>
    </form>
  </div>
</div>
