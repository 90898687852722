import { Component, OnInit } from '@angular/core';

import { AddtrendingComponent } from '../addtrending/addtrending.component';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TrendingService } from 'src/app/_service/trending.service';

@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.css'],
})
export class TrendingComponent implements OnInit {
  trendingResponse: any;
  trendingResponseData: any;
  refreshTrendingComponentResponse: any;
  deleteTrendingResponse: any;

  constructor(
    private bsModalService: BsModalService,
    private trendingService: TrendingService,
    private toastrService: ToastrService) {
    this.getTrendings();
  }

  ngOnInit(): void {
    this.refreshTrendingList();
  }

  /**Get Trendings Images */
  getTrendings() {
    this.trendingService.getTrendings()
      .subscribe((response) => {
        this.trendingResponse = response;
        if (this.trendingResponse.err == 0) {
          this.trendingResponseData = this.trendingResponse.trendings;
        }
      });
  }

  /**Open Modal For Add New Trending Image */
  openAddTreadingModal() {
    this.bsModalService.show(AddtrendingComponent, Object.assign({}, { class: 'modal-dialog-centered', ignoreBackdropClick: true }));
  }

  /**Delete Trending */
  deleteTrending(trendingId) {
    this.trendingService.deleteTrending(trendingId)
      .subscribe((response) => {
        this.deleteTrendingResponse = response;
        if (this.deleteTrendingResponse.err == 0) {
          this.toastrService.success(`${this.deleteTrendingResponse.msg}`);
          this.getTrendings();
        } else {
          this.toastrService.error(`${this.deleteTrendingResponse.msg}`);
        }
      });
  }

  /**Refresh Trending List When Add New Trending */
  refreshTrendingList() {
    this.trendingService.trendingRefreshState
      .subscribe((response) => {
        this.refreshTrendingComponentResponse = response;
        if (this.refreshTrendingComponentResponse) {
          this.getTrendings();
        }
      });
  }
}
